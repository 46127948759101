;(function ($) {
	$.fn.slideApp = function slideMg(_contentSlide, _itemSlide, prev, next, slideChildren, slideNbr){
		// init à 0 pour le slider dans ma WishList
		var calcPos = 0;
		// element this
		var _element = $(this);
		// gerer le mousewheel
		var scrollHover = false;
		// on stock les classes dans les variables
		var direction ={ 
			'btnPrev' : $(prev), 
			'btnNext' : $(next)
		};
		
		var Options = {
			direction : true,
			'cssParamLeft' : {
				'display' :'block'
			},
			'cssParamRight' : {
				'display' :'block'
			}
		}
		var Defaults = {
			'cssParamLeft' : {
				'display' : 'none'
			},
			'cssParamRight' : {
				'display' : 'none'
			}
		}
		// variable pour le calcul des nombres d'item affiché et recuperation des hauteur et largeur 
		var _lengthItem = $(_itemSlide).length;
		var _widthSingle = $(_itemSlide).first().length;
		var  _heightSlide = _lengthItem * $(_itemSlide).outerHeight();
		var  _widthSlide = $(_itemSlide).width();
		
		var nbItemBySlide = {
			active : true,
			number : slideNbr,
			height : function(){
				return this.number * $(_itemSlide).outerHeight();
			 }
		}
		
		var nbItemDefaults = {
			 active : false,
			 number : $(_itemSlide).length,
			 height : function(){
				return "800px"; 
			 },
			 width : function(){
				return _widthSlide;
			}
		}
		
		// on definie les différents mode
		var settings = $.extend(true,{}, Defaults, Options);
		var settingsSlide = $.extend(true,{}, nbItemDefaults, nbItemBySlide);
		if(settings.direction === true){
			// gestion des style pour le controle du slider
			direction.btnPrev.css(settings.cssParamLeft);
			direction.btnNext.css(settings.cssParamRight);
		}
		// on applique au parent qui contient le slider une hauteur
		if(settingsSlide.active === true){
			/*_element.css({
				//'width' :  settingsSlide.width(),
				'height' : settingsSlide.height()
			});*/
			
			$(this).find(slideChildren).css({
				//'width' :  settingsSlide.width(),
				'height' : settingsSlide.height()
			});
		}
		// on applique une largeur et hauteur a l'aide de l'item
		_element.find(_contentSlide).css({
			top: '0',
			position:'absolute',
			//width : _widthSlide,
			height : _heightSlide
		});
		function nextSlide(){
			var _heightSingle = $(_itemSlide).first().outerHeight();
			var _heightSlide = (_lengthItem * _heightSingle) - $(_element).outerHeight();
			
			//détermine si notre curseur se situe dans la partie scrollable
			if (calcPos < _heightSlide ){
				calcPos = calcPos + _heightSingle;
				$(_contentSlide).stop()
				.animate({
					'top' : "-"+calcPos+"px"
				}, 500);
			}
		}
		//gestion de l'evenement clique pour l'element suivant  
		direction.btnNext.on('click', function(e){
			e.preventDefault();
			nextSlide();
		});
		
		function prevSlide(){
			var _heightSingle = $(_itemSlide).first().outerHeight();
			var firstItem = $(_itemSlide).first().offset().top;
			
			if(calcPos > 0 ){
				calcPos = calcPos - _heightSingle;
				$(_contentSlide).stop()
				.animate({
					'top' : "-"+calcPos+"px"
				}, 500);
			}
		}
		//gestion de l'evenement clique pour l'element precedent 
		direction.btnPrev.on('click', function(e){
			e.preventDefault();
			prevSlide();
		});
	
		$(this).on('mouseover', function(){
			scrollHover = true;
	
			function wheel(event){
				if (scrollHover === true ){
					var delta = 0;
					if (!event) event = window.event;
					if (event.wheelDelta) {
						delta = event.wheelDelta/120; 
					} else if (event.detail) {
						delta = -event.detail/3;
					}
					if (delta)
						handle(delta);
						if (event.preventDefault)
								event.preventDefault();
						event.returnValue = false;
			}
		}
		
			/* Initialization code MouseWheel. */
			if (window.addEventListener)
				window.addEventListener('DOMMouseScroll', wheel, false);
			window.onmousewheel = document.onmousewheel = wheel;
	
		function handle(delta) {
				if (delta < 0)
					/* something. */
					prevSlide();
				else if( delta > 0)
					/* something. */
					nextSlide();
		}// JavaScript Document 
	
	});
		$(this).on('mouseleave', function(){
			scrollHover = false;
		});
	}
}(jQuery));