/*
  This file regroups javascript code to handle cart functions
*/
Sandro.cart.bindEvents = function() {

	Sandro.cart.updateProductQuantity();
	Sandro.cart.updateProductSize();
	Sandro.cart.taxesCalculation();
	Sandro.cart.initSwitchSize();

	//if shoprunner available on cart, remove possible residual hash from the URL to avoid unwanted login/logout shoprunner behavior
	if ($('div[name="sr_headerDiv"]').length > 0) {
		history.pushState("", document.title, window.location.pathname + window.location.search);
	}

	$('.impulse-push button').click(function() {
		var postdata = {
			cartAction : "add",
			pid : $(this).prev('.product-tile').data('itemid')
		}

		app.cart.update($.param(postdata), function(data) {
			location.reload();
		});
	});
	var cartRow;
	$('.alertunavalability').on('click', function(e) {
		e.preventDefault();

		cartRow = $(this).closest('.unavailable-pdt');
		cartRow.addClass('show-alert-email');

		//cartRow.parent().find('.alert-product-unavailable').css('display', 'block');
		cartRow.parent().find('.alert-product-unavailable .unavail-email').focus();

	});


	$('.alertunavalability-valid').on('click', function(e) {
		e.preventDefault();
		var email = $(this).parent().find('.unavail-email').val();
		var url = $(this).attr('href');
		if(url.length > 0) {
			url += '&email=' + email;
		}

		// Find the div in which the success message will be display.
		var parentDiv = $(this).closest('.item-list');
		var message = $('<span class="productalert-result"></span>');


		if(!$(this).parent().find('.unavail-email').valid()) return;
		$.ajax({
			url : url,
			success : function (response) {
				if (response.success == true) {
					if (parentDiv.length) {
						if (response.RemoveProduct == 'true') {
							// Display result message after 4s
							if ($(window).width() < 767) {
								parentDiv.find('.cart-row').hide();
								parentDiv.css('padding','0');
								parentDiv.html($('.product-unavailable-alert').clone());
							} else {
								parentDiv.find('.alert-product-unavailable').html($('.product-unavailable-alert').clone());
							}
							setTimeout(function() {
								$('#cart-wrapper').load(app.urls.cartShow + " #cart-wrapper");
							} ,3000);
						} else {
							message.html(response.message);
							// Show the result message
							message.addClass('success').css('display', 'none');
							parentDiv.append(message);

							$('.alertunavalability').fadeOut();
							$('.unavail-remove').fadeOut();

							parentDiv.find('p').fadeOut(function() {
								message.fadeIn();
							});

							// Hide the result message after 4s
							/*
							setTimeout(function() {
								message.fadeOut(function() {
								divMessage.find('p').fadeIn();
								});

							} ,4000);
							*/
						}
					}
				} else {
					message.text('Une erreur est survenue');
					message.addClass('error');
					parentDiv.append(message);
				}
			},

			error : function () {
				// ToDo : Handle an error
				//alert('error');
			}
		});
	});

	$('.alertunavalability-cancel').on('click', function(e) {
		e.preventDefault();
		$(this).parent().css('display', 'none');
		var cartRow = $(this).closest('.item-list').find('.cart-row');
		cartRow.find('.alertunavalability').css('display', 'block');
		cartRow.find('.unavail-remove').css('display', 'block');

		$(this).closest('.unavailable-pdt').removeClass('activeAv');
	});

	$('.unavail-remove').on('click', function(e){
		e.preventDefault();
		$(this).closest('.cart-row').find('.button-text.remove').trigger('click');
	});

	$(document).on('click', '.connexion-checkout-mode', function(event){
		event.preventDefault();
		$('.login-popin').addClass('logging-mode');
		$('.ui-dialog-title').html(app.resources.CHECKOUT_LOGIN_POPIN_TITLE);
	});

	$(document).on('click', '.continue-guest-mode', function(event){
		$('.continue-as-guest button').click();

	});

	$(document).on('click', '.popin-subtitle .back-button, .resetPasswordConfirm button', function(event){
		event.preventDefault();
		$('.login-form-bock').toggleClass('reset-password-open');
		$('#reset-password-block').removeClass('password-reset-confirm');
		$('.resetPasswordConfirm').remove();
	});

	$(document).on('click', '#password-reset', function(event){
		event.preventDefault();
		$('.login-form-bock').toggleClass('reset-password-open');
		$('#PasswordResetForm').validate(app.validator.settings);
	});

	$(document).on('submit', '#PasswordResetForm', function(e) {
		e.preventDefault();
		var formElement = $(this);



		if (formElement.validate().form()) {
			var formData = new FormData(formElement.get(0)),
				url = app.util.appendParamToURL(formElement.attr('action'), "format", "ajax");

			$.ajax({
				url: url,
				type: 'POST',
				data: formData,
				async: true,
				success: function (data) {
					var $result = $(data).filter('#recoverpwd');
					if (!$result.length) {
						$('.reset-password-container').append($(data).find('.resetPasswordConfirm'));
						$('#reset-password-block').addClass('password-reset-confirm');
					} else {
						$('#recoverpwd .error-message').remove();
						$result.find('.error-message').insertAfter('#PasswordResetForm');
					}
				},
				cache: false,
				contentType: false,
				processData: false
			});
		}

	});
	$(document).off('click','.unAuthenticatedUser').on('click','.unAuthenticatedUser', function(e){

		e.preventDefault();

		if ( $(e.target).hasClass('minicart-checkout') ) {
			if (!app.constants.ENABLE_GUEST_LOGIN_PROMPT) {
				window.location.href = app.urls.shippingPageUrl;
				return;
			}
			var formElement = $('a.unAuthenticatedUser'),
			formData = new FormData(),
			btnElement = $(this),
			url = formElement.attr('href');
		} else {
			var formElement = $('#cart-items-form'),
			formData = new FormData(formElement.get(0)),
			btnName = $(this).attr('name').toString(),
			btnValue =  $(this).val().toString(),
			btnElement = $(this),
			url = formElement.attr('action');

			formData.append(btnName,btnValue);
		}

		$.ajax({
			url: url,
			type: 'POST',
			data: formData,
			success: function (data) {
			    var $result = $(data).filter('.alignment.accountConnect');
			    $result.dialog({
			    	dialogClass: 'checkout-style update-form-style login-popin',
			    	modal : true,
			    	resizable : false,
			    	draggable: false,
			    	title: btnElement.data('titlepopin'),
			    	open: function(event) {
			    		$('#loginform form').validate(app.validator.settings);
			    	},
			    	close: function(event) {
			    		$(this).remove();
			    	}
			    });
			},
			error: function(xhr, text, error) {
				console.log(xhr.responseText);
			},
			cache: false,
			contentType: false,
			processData: false
		});
	});

	/*$(document).on('click', '.login-popin #dwfrm_login .login-button', function(e){
		e.preventDefault();

		var formElement = $(this).closest('form');

		if (formElement.validate(app.validator.settings).form()) {

			var	formData = new FormData(formElement.get(0)),
				btnName = $(this).attr('name').toString(),
				btnValue =  'submit',
				url = app.util.appendParamsToUrl(formElement.attr('action'), {format:"ajax"});

			formData.append(btnName,btnValue);

			$.ajax({
				url: url,
				type: 'POST',
				data: formData,
				success: function (response) {
					var $loginBlock = $(response).find('.login-form-bock');
					if ($loginBlock.length > 0) {
						$('.login-form-bock #loginform').replaceWith($loginBlock.find('#loginform'));
					} else {
						window.location = app.urls.shippingStart;
					}
				},
				cache: false,
				contentType: false,
				processData: false
			});
		}
	});*/

	$('input[type=radio][name$=cart_giftWrapping_isGift]').change(function() {
		if (this.value == 'true') {
			$('.add-gift-message').addClass('open');
		}
		else {
			$('.add-gift-message').removeClass('open');
			$('input[type=radio][id$=_giftWrapping_addGiftMessage_false]').attr('checked',true);
			$('#giftMessagePanel').removeClass('open');
		}
	});

	$('input[type=radio][name$=_giftWrapping_addGiftMessage]').change(function() {
		if (this.value == 'true') {
			$('#giftMessagePanel').addClass('open');
		}
		else {
			$('#giftMessagePanel').removeClass('open');
		}
	});
	if ($('.swiper-product-push').length) {
		var cartPushSlider = $('.swiper-product-push').swiper({
			mode : 'horizontal',
			speed : 600,
			calculateHeight: true,
			slidesPerView : 1
		});
		function slidePerView() {
			if($(window).width() < 768){
				cartPushSlider.params.slidesPerView = 1;
				cartPushSlider.params.resistance= "100%";
			}else{
				cartPushSlider.params.slidesPerView = 4;
				cartPushSlider.params.resistance = "100%";
			}
		}
		slidePerView();

		$(window).resize(function(event){
			slidePerView();
		});
	}
}


/*
	Cart Page Update Product Quantity using AJAX
*/
Sandro.cart.updateProductQuantity = function() {
	$("#cart-items-form").on("click", ".cart-updatequantity", function (e) {
		e.preventDefault();
		var pid = $(this).attr('data-cartpid');
		var plid = $(this).attr('data-cartuuid');
		var qty = $(this).attr('data-cartqty');
		app.progress.show('div.cart-scrolling');
		$.ajax({
			url : app.urls.updateLineItemQuantity,
			data: {
				format: "ajax",
				pid: pid,
				uuid: plid,
				qty: qty
			}
		})
		.done(function (response) {
			app.cart.refresh();
		});
	});
}

/*
	Cart Page Update Product Size using AJAX
*/
Sandro.cart.updateProductSize = function() {
	$("#cart-items-form").on("change", ".variation-select-size", function (e) {
		e.preventDefault();
		var pid = $(this).find("option:selected").attr('data-cartpid');
		var plid = $(this).closest(".cart-row").attr('data-plid');
		var qty = $(this).closest(".cart-row").data('itemqty');
		if ($(this).find("option:selected").hasClass('unselectable')) {
			return;
		} else {
			app.progress.show('div.cart-scrolling');
			$.ajax({
				url : app.urls.minicartUpdateSize,
				data: {
					format: "ajax",
					pid: pid,
					uuid: plid,
					Quantity: qty
				}
			})
			.done(function (response) {
				app.cart.refresh();
			});
		}
	});
}