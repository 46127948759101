var dataLayer = dataLayer || [];

function launchGTMEvents(){
	// Put trackerData in parent scope so it can be accessed from inside the ajax call and init function
	var trackerData = app.page.trackerData;

	
	$(document).on('newsletter.subscription', function() {
		var subscriptionLayer = {};
		$.extend(true, subscriptionLayer, userData);
		subscriptionLayer.event = 'newsletterSubscription';
		dataLayer.push(subscriptionLayer);
		
	});
	//PDP
//	$(document).on('product.switch.variation', function() {
//		$('.product-variations .color a.swatchanchor').on('click', function(){
//			dataLayer.push({
//				'masterIDColor' : $(this).attr('data-variationparameter').replace('dwvar_','').replace('color=','')
//			});
//		});
//	});
	
	
	$('.item-actions a.addToBasket').on('click', function(e){
		var product = $(this).data("product");
		if (product) {
			$(document).trigger("pdp.addtocart", [product]);
		}
	});
	$('.addAllToBasket .addBtn').on('click', function(e) {
		dataLayer.push({
			"event": "addAllToCart",
			"from": "wishlist",
			  "ecommerce": {
			    "currencyCode": trackerData.defaultCurrency,
			    "add": {
			      "products": $(this).data('products')
			    }
			  }
		});
	});
	
	if(app.page.ns === 'product') {
		dataLayer.push({
			'event' : 'pdp-pageview',
			'ecommerce' : {
				'detail': trackerData.product || ''
			}
		});
	}

	$(document).on('replenishment.request', function() {
		dataLayer.push({
			'event': 'customEvent',
			'customCategorie': 'engagement',
			'customAction': 'replenishmentRequest',
			'customLabel': $('#product-content .product-number span').text()
		});
	});

	// eReservation
	$(document).on('ereservation.process', function(e, product, process) {
		
		var reservation = {};
		if (app.page.ns === 'product' && app.page.trackerData.hasOwnProperty('product')) {
			var product = app.page.trackerData.product.products[0];
			$.extend(true, reservation, userData);
			reservation.event = 'clickeReservation';
			reservation.productName = product.name;
			reservation.reservationAction = process;
			dataLayer.push(reservation);
		}
	});

	// CART & CHECKOUT
	
	if(app.page.ns === 'cart') {
		if (trackerData.ecommerce.products) {
			dataLayer.push({
				'event': 'stepCheckout',
				'ecommerce' : {
					'checkout' : {
						'actionField': {'step': '1'},
						'products': trackerData.ecommerce.products
					}
				}
			});
		}
	}
	if(app.page.ns === 'checkout') {
		if (trackerData.ecommerce.products) {
			dataLayer.push({
				'event': 'stepCheckout',
				'ecommerce' : {
					'checkout' : {
						'actionField': {'step': '2'},
						'products': trackerData.ecommerce.products
					}
				}
			});
		}
	}
	if(app.page.ns === 'billing') {
		if (trackerData.ecommerce.products) {
			dataLayer.push({
				'event': 'stepCheckout',
				'ecommerce' : {
					'checkout' : {
						'actionField': {'step': '3'},
						'products': trackerData.ecommerce.products
					}
				}
			});
		}
	}
	
	$(document).on('click', 'li.grid-tile a', function(e) {
		if ($(this).parents("div.quick-actions").length <= 0) {
			var eClickDataLayer = $(this).closest('li').data('tag_click_product');
			dataLayer.push(eClickDataLayer);
		}
		
	});
	$(document).on('payment.step', function() {
		var virtualPageView = {};
		$.extend(true, virtualPageView, userData);
		virtualPageView.event = 'virtualPageview';
		virtualPageView.checkoutStep = 3;
		virtualPageView.page = window.location.pathname + '/payment-information';
		dataLayer.push(virtualPageView);
		dataLayer.push({
			'event': 'stepCheckout',
			'ecommerce' : {
				'checkout' : {
					'actionField': {'step': '3'},
					'products': trackerData.ecommerce.products
				}
			}
		});
	});
	$(document).on('add.to.wishlist', function(e, product) {
		var addToWishlist = {};
		if (product) {
			$.extend(true, addToWishlist, userData);
			addToWishlist.event = 'clickAddToWishlist';
			addToWishlist.productName = product.name;
			addToWishlist.wishlistAction =  'add';
			dataLayer.push(addToWishlist);
		}
	});
	
	$(document).on('plp.filter', function(e, id, value, pageUrl) {
		var virtualPageView = {};
		$.extend(true, virtualPageView, userData);
		virtualPageView.event = 'virtualPageView';
		virtualPageView.filterSelected = id + '=' + value;
		virtualPageView.page =  window.location.pathname + pageUrl;
		dataLayer.push(virtualPageView);
		
	});
	$(document).on('form.error', function(e, formName, errMessage) {
		var formSubmitError = {};
		$.extend(true, formSubmitError, userData);
		formSubmitError.event = 'formSubmittedWithError';
		formSubmitError.errorMessage = errMessage;
		formSubmitError.formName =  formName;
		dataLayer.push(formSubmitError);
	});
	$(document).on('pdp.addtocart', function(e, data) {
		var addtocartLayer = {};
		if (data) {
			$.extend(true, addtocartLayer, userData);
			addtocartLayer.event = 'addToCart';
			addtocartLayer.productName = data.name;
			dataLayer.push(addtocartLayer);
			// EEcommerce
			data.quantity = 1;
			dataLayer.push({
				'event': 'addToCartEE',
				'ecommerce' : {
					'currencyCode': trackerData.defaultCurrency,
					'add': {
						'products' : [data]
					}
				}
			});
		}
	});
	$('.cart-impulse-sell button').on('click', function(e){
		var data = $(this).closest('.impulse-push').data('product');
		$(document).trigger("pdp.addtocart", [data]);
	});
    $(document).on('storelocator.clickstore', function(e, storeAction, storeLocation) {
		var storeLayer = {};
		$.extend(true, storeLayer, userData);
		storeLayer.event = 'clickStore';
		storeLayer.storeAction = storeAction;
		storeLayer.storeLocation = storeLocation;
		dataLayer.push(storeLayer);
	});
    $(document).on('cart.removefromcart', function(e, data) {
		var addtocartLayer = {};
		if (data) {
			$.extend(true, addtocartLayer, userData);
			addtocartLayer.event = 'removeFromCart';
			addtocartLayer.productName = data.name;
			dataLayer.push(addtocartLayer);
			// EEcommerce
			data.quantity = 1;
			dataLayer.push({
				'event': 'removeFromCartEE',
				'ecommerce' : {
					'currencyCode': trackerData.defaultCurrency,
					'remove': {
						'products' : [data]
					}
				}
			});
		}
	});
	$('.cart-scrolling button.remove ').on('click', function(e) {
		var removeData =  $(this).data('tracker');
		$(document).trigger("cart.removefromcart", [removeData]);
	});
	

}