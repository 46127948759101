Sandro.account.bindEvents = function(){
	// declarer la variable slide
	Sandro.account.inputsFocus();
	if ($('#loginform').length) {
		Sandro.account.closeModalPwdReset();
	}
	Sandro.account.hijackEnterKeyHitOnRegistrationForm();
	Sandro.account.birthdateInit();
	Sandro.account.countryInit();
	Sandro.account.getQuantitySelectorWishlist();
	Sandro.account.removeWishlistAjax();
	Sandro.account.selectedMenu();
	Sandro.account.navMyAccount();
	Sandro.account.accordeonDetailCommande();
	Sandro.account.popinReturnOrder();
	Sandro.account.ongletLogin();
	Sandro.account.returnNewTab();
	Sandro.account.bindFormSubmit();
	Sandro.account.privateSaleLogin();
	Sandro.account.editAccountPageEvents();
	Sandro.account.registerPageEvents();

	var btnHide = $( '.accountDashboard h2' );

	btnHide.on('click',function(){
		if($(window).width() < 759){
			var active = $(this).parent().siblings('.active');
			if (active.length){
				active.find('h2').trigger('click');
			}
			$(this).closest('.accountItem').toggleClass('active');
			$(this).nextAll().slideToggle('500');
		}
	});

	$(window).resize(function(e) {
		if($(window).width() >= 759){
			$('.accountItem').children(':hidden').each(function( index ) {
				$( this ).css('display','');
			});

		}
	});

	Sandro.account.returnsStatus();
};

Sandro.account.returnNewTab = function() {

	$(".btn-return-newtab").on("click", function(){
		var returnUrl = $(this).data("return-url");
		window.open(returnUrl,'_blank');
	});

};

Sandro.account.popinReturnOrder = function() {
	$(".btn-popin-returnorder").on("click", function() {

		$(".popin-returnorder").dialog({
		    closeText: app.resources.CLOSE,
		    draggable: false,
		    dialogClass : 'popin-returnorder-dialog',
		    height: "auto",
	        width: "600px",
		    modal: true,
		    position: "center"
		});

	});
}

Sandro.account.navMyAccount = function() {
	/* var */
	var elNav = {};

	elNav = {
		navAccountWrap : $(".sub-nav-dropdown-mob"),
		nav : $(".sub-nav-dropdown-mob .nav-myaccount"),
		navCurrent : $(".sub-nav-dropdown-mob .current-menu")
	};

	/* responsive */
	if(elNav.nav.length){
		elNav.navCurrent.on("click", function() {
			$(this).toggleClass("open");
			elNav.nav.toggleClass("open-nav-myaccount-mob");
		});
	}
}

Sandro.account.accordeonDetailCommande = function() {

	/* Open Accordeon on Click */
	if($(".wrapper-accordeon").length > 0){

		$(".wrapper-accordeon").each(function() {

			var _this = $(this);

			_this.find(".btn-accordeon").on("click",function() {

				var wrapAccor = $(this).parent(".wrapper-accordeon");

				_this.find(".content-accordeon").slideToggle("fast", function() {
					wrapAccor.toggleClass("accordeon-close");
				});
			});
		});

	}

}

Sandro.account.ongletLogin = function() {

	/* Switch Onglet on Click */
	if($(".wrapper-onglet-content").length > 0) {

		$(".nav-onglet li").each(function() {

			var _this = $(this);
			var thisIndex = _this.index();

			_this.on("click", function() {
				if (!_this.hasClass("nav-onglet-active")){
					$(".nav-onglet li").removeClass("nav-onglet-active");
					$(".nav-content").removeClass("nav-content-active");

					_this.addClass("nav-onglet-active");
					$(".nav-content").eq(thisIndex).addClass("nav-content-active");
				}
			});

		});

	}

}

Sandro.account.removeWishlistAjax = function() {
	$(document).on('click', 'div.wishlist-ajax a.removeFrom', function(e) {
		e.preventDefault();
		app.ajax.load({
			url: app.util.ajaxUrl(this.href),
			callback: function(data) { $('div.wishlist-ajax').replaceWith(data); }
		});
	});
}

Sandro.account.getQuantitySelectorWishlist = function(){
	$('select.selectorQuantity').on('change',function(e){
		var selector = $(this).data('selector');

		var quantity = $(this).val();
		$('div.row').find('quantity-choosen-'+selector).text(quantity);
		var price = $(this).data('price');
		var total = parseInt(quantity) * parseInt(price);

        $('span.' + selector).text($('span.' + selector).text().replace(/[0-9]+/, total));
	});
};

Sandro.account.inputsFocus = function(){
	$(window).load(function() {
		// hide label for login
		$('.tableRow input[type=text], .tableRow input[type=password], .tableRow input[type=number], .tableRow input[type=tel]')
			.each(function() {
				if ($(this).val()) {
					$(this).siblings('.form-caption').hide();
				}
			})
			.focus(function(){
				$(this).siblings('.form-caption').hide();
			})
			.blur(function(){
				if (!$(this).val() && !$(this).hasClass('error-message')) {
					$(this).siblings('.form-caption').show();
				}
			});
	});
};

Sandro.account.hijackEnterKeyHitOnRegistrationForm = function(){
	 var validateButtonSelector = "#RegistrationForm .apply";
	 var validateButton = $(validateButtonSelector);

	 if(validateButton.length > 0){
		 $(document).on("keypress", function(e){

			 var code = (e.keyCode ? e.keyCode : e.which);
			 if(code == 13)
			 {
				 e.preventDefault();
				 validateButton.trigger("click");
				 e.stopPropagation();
			 }
		 });
	 }
}


Sandro.account.closeModalPwdReset = function() {
	$(window).load(function() {
		$('.resetPwd .closeModale').live('click',function(e){
			e.preventDefault();
			$('#dialog-container').dialog('destroy');
		});
	});
};

Sandro.account.birthdateInit = function(){

	function fillInBirthDateInput() {
		var birthdate = $('#birthdate-day-select').attr('value') + "/" + $('#birthdate-month-select').attr('value') + "/" + $('#birthdate-year-select').attr('value');
		if($('#dwfrm_profile_customer_birthday').length>0 && birthdate.length > 2){
			$('#dwfrm_profile_customer_birthday').val(birthdate);

			// Check for age and auto-check the age validation box
			if(app.validator.regex.birthDate.test(birthdate)){
				try {
					var ageCheck = $("#legalage, input[name$='_legalage']");
					var now = new Date();
					var then = $.datepicker.parseDate( "dd/mm/yy", birthdate ); // Relies on the presence of the jquery DatePicker plugin
					// The difference between now and then is returned in milliseconds, and we don't need them.
					// 31556952 = 365.2425*24*60*60, the approximate average number of seconds in a year (accurate to ~2h over the tested range)
					var age = Math.floor( (now - then)/1000 ) / 31556952;
					ageCheck.attr("checked", (age >= 16));
					ageCheck.closest('form').validate().element(ageCheck);
				} catch(e) {
					// Do nothing, maybe the parseDate of the DatePicker threw an exception because the entered date is impossible, like 31/02/2018
				}
			}
		}
	}

	$(document).on('change','.birthdate-select', function(e){
		e.preventDefault();
		fillInBirthDateInput();
	});

	// If a date was selected before the handlers were bound, make sure the input is filled in
	fillInBirthDateInput();
};

Sandro.account.returnsStatus = function() {
	// Quick hack to style the ::after content of each return status label.
	$('.myAccount .return div.status').each(function() {
		$(this).find('span.return-status:last').addClass('last');
	})
}

Sandro.account.returnsHelper = function()
{
	var _returns = this;
	var urlReturn = "";
	var urlReturnDisplay = "";
	var msgErrorGeneric = "";
	var msgErrorQty = "";
	var msgErrorReason = "";
	var currentErrorMsg = "";

	this.init = function()
	{
		_returns.Items = new Array();

		var results = $("div.orderItems").eq(0);
		if(results.length > 0)
		{
			urlReturn = $(results).data("updatereturnurl");
			urlReturnDisplay = $(results).data("updatereturndisplayurl");
			msgErrorGeneric = $(results).data("errormessagegeneric");
			msgErrorQty = $(results).data("errormessageqty");
			msgErrorReason = $(results).data("errormessagereason");
		}
	}

	this.isValid = function()
	{
		var nbZeroQties = 0;
		var qtiesAvailable = false;
		var results = $("div.orderItems > .infoBlock");
		if(urlReturn != "" && results.length > 0 && urlReturnDisplay != "")
		{
			for(i=0; i<results.length; i++)
			{
				var infoBlock = $(results[i]);
				var productID = infoBlock.data("productid");
				if(infoBlock.next(".row"))
				{
					var qtyToReturn = infoBlock.next(".row").find("select.returnQtySelect");
					if(qtyToReturn && qtyToReturn.length > 0)
					{
						if(qtyToReturn.val() > 0)
							qtiesAvailable = true;
						else if(qtyToReturn.val() <= 0)
							nbZeroQties++;
					}
				}
			}

			if((!qtiesAvailable) || (nbZeroQties == results.length))
			{
				currentErrorMsg = msgErrorQty;
				return false;
			}
		}

		var tmp_refundReason = $(".refundReason").val();
		if(tmp_refundReason != "-10")
			return true;
		else if(tmp_refundReason == "-10")
			currentErrorMsg = msgErrorReason;
		else
			currentErrorMsg = msgErrorGeneric;

		return false;
	}

	this.displayErrorMessage = function()
	{
		$(".returnErrorMsg").html(currentErrorMsg);
	}

	this.extract = function()
	{
		var results = $("div.orderItems > .infoBlock");
		if(results.length > 0)
		{
			for(i=0; i<results.length; i++)
			{
				var infoBlock = $(results[i]);
				var productID = infoBlock.data("productid");
				if(infoBlock.next(".row"))
				{
					var qtyToReturn = infoBlock.next(".row").find("select.returnQtySelect");
					if(qtyToReturn && qtyToReturn.length > 0)
					{
						var obj = {};
						obj.productID = productID;
						obj.qtyToReturn = qtyToReturn.val();
						if(obj.qtyToReturn > 0)
							_returns.Items.push(obj);
					}
				}
			}
		}
	}

	this.update = function()
	{
		if(_returns.Items.length > 0)
		{
			var root = this;
			$.ajax({
				type : "POST",
				url: urlReturn,
				data: this.returnData(),
				datatype: "json"
			})
			.success(function(data, status, jqXHR) {
				if(data && data.Result == "OK")
					root.redirect(data.ID);
				else
					root.displayErrorMessage();
			})
			.error(function(e) {
				root.displayErrorMessage();
			});
		}
	}

	this.returnData = function()
	{
		var data = {};
		data.reason = $(".refundReason").val();

		for(i=0; i<_returns.Items.length; i++)
		{
			data[_returns.Items[i].productID] = _returns.Items[i].qtyToReturn;
		}

		return data;
	}

	this.redirect = function(id)
	{
		if(id != null && id != "")
		{
			location.href = urlReturnDisplay + "&returnid=" + id;
		}
		else
			location.reload();
	}

	this.init();
	return _returns;
};

Sandro.account.selectedMenu = function(){
	var dashboard = $('.myAccount .dashboardIntro').html();
	var wishlist = $('.myAccount .wishlistPage').html();
	var perso = $('.myAccount .perso').html();
	var address = $('.myAccount .addressList').html();
	var orders = $('.myAccount .orderhistory').html();
	var orderDetail = $('.myAccount .orderDetails').html();
	var refund = $('.myAccount .return').html();
	var refundpartempty = $('.myAccount.returnsrefunds').html();
	var paymentinstrumentlist = $('.myAccount.paymentinstrumentlist').html();
	var paymentWallet = $('.myAccount .payment-wallet').html();
	var returnAndRefundPolicy = $('.myAccount .returnandrefundpolicy').html();
	var changePassword = $('.myAccount .changepw').html();
	var voucher = $('.myAccount .voucherIntro').html();

	if(dashboard != null){
		$(".dashboard").addClass("active");
	}
	else if(wishlist != null){
		$(".wishlist").addClass("active");
	}
	else if(perso != null){
		$(".perso").addClass("active");
	}
	else if(address != null){
		$(".address").addClass("active");
	}
	else if(paymentWallet != null){
		$(".wallet").addClass("active");
	}
	else if(voucher != null){
		$(".voucher").addClass("active");
	}
	else if(orders != null || orderDetail != null){
		$(".order").addClass("active");
	}
	else if(returnAndRefundPolicy != null){
		$(".returnandrefundpolicy").addClass("active");
	}
	else if(changePassword != null){
		$(".resetpass").addClass("active");
	}
}

Sandro.account.bindFormSubmit = function() {
	//Minor validation and ajax call
	$(document).on('click', '#EasyRegister .easy-register-apply', function(e){
		var easyRegisterForm = $("#EasyRegister");
		var accountShowLink = $('#accountShowLink').attr('value');
		if(easyRegisterForm.validate().form()){
			e.preventDefault();
			var passwordField = easyRegisterForm.find(".easy-register-password input");
			if(passwordField.val().length < 8){
				passwordField.focus();
			}
			else{
				var requestData = easyRegisterForm.serialize();
				var buttonName = $("#EasyRegister .easy-register-apply").attr('name');
				var buttonValue = $("#EasyRegister .easy-register-apply").attr('value');
				requestData += '&' + buttonName + '=' + buttonValue;
				$(".easy-register-apply").attr('disabled','disabled');
				$.ajax({
					url : easyRegisterForm.attr('action'),
					type : "POST",
					data : requestData,
					success : function(response){
						if(response){
							var accountShowRedirect = $(response).filter('#accountShowRedirect');
							var postLoginRedirect = $(response).find('#postLoginRedirect');
							if(accountShowRedirect.length != 0){
								$(document).find('div.easy-register-bloc').html(response);
							} else if (postLoginRedirect.length != 0 && postLoginRedirect[0].value != null) {
								window.location.replace(postLoginRedirect[0].value);
							} else {
								window.location.replace(accountShowLink);
							}
						}else{
							$(".easy-register-apply").removeAttr("disabled");
						}
					},
					error : function(){
						window.location.replace(accountShowLink);
					}
				});
			}
		}
	});

	// Update login page form actions with the correct one when sending it.
	// This is needed because we use a Continue node on Account-RequireLogin
	// and it doesn't seem to exist a way to pass URLUtils.httpsContinue()
	// into the content slot
	$('form#dwfrm_login, form#dwfrm_login_register').submit(function(e){
		$(this).attr("action", $('input#loginContinueAction').val());
		return true;
	});
};

Sandro.account.privateSaleLogin = function(){
	if ($('.event-login-page').length) {
		$('#wrapper').addClass('privateSalesMain');
	}
};
Sandro.account.countryInit = function() {
	$(document).on("change IEchange", ".checkout-shipping select[id$='_country'], .addressEdit select[id$='_country']", function(){
		$(window).trigger('address.load.provinces',{country: this});
	});
}
Sandro.account.registerPageEvents = function() {
	if ( $("div.editProfile").length > 0 ) {
		return;
	};

	if ( $('.tableRow.verifybtn').length < 1 )
		return;
	$(document).off("click",".get-code").on("click",".get-code", function() {
		$(this).attr("disabled","disabled");
		var timeoutTime = app.constants.RESEND_SMS_INTERVAL * 1000;
		$(this).css("opacity", 0.5);
		var that = this;
		setTimeout(function() {
			$(that).removeAttr("disabled");
			$(that).css("opacity", 1);
		}, timeoutTime);

		$(".get-code-message").empty();
		var phoneNumber = $("input[name='dwfrm_profile_customer_phone']").val();
		$(window).trigger('register.triggercaptcha',[{'form-type': pageContext.type,'url': app.urls.getVerificationCode,'phone' : phoneNumber,'reload': 0}, function(data) {
			if(data.status == "ok") {
				$(".get-code-message").text(app.resources.GetVerificationCodeOK);
			} else {
				if (data.status != "duplicated") {
					$(".get-code-message").text(app.resources.GetVerificationCodeFailed);
				}
			}
		}]);
	});
}

Sandro.account.editAccountPageEvents = function() {
	if ( $("div.editProfile").length == 0 ) {
		return;
	};
	$(document).on("keyup","input[name$='profile_customer_phone_fake']" , function() {
		$(".verifybtn").removeClass("hidden");
		$(".verificationcode").removeClass("hidden");
	});

	$(document).off("click",".get-code").on("click",".get-code", function() {
		$(this).attr("disabled","disabled");
		var timeoutTime = app.constants.RESEND_SMS_INTERVAL * 1000;
		$(this).css("opacity", 0.5);
		var that = this;
		setTimeout(function() {
			$(that).removeAttr("disabled");
			$(that).css("opacity", 1);
		}, timeoutTime);

		$(".get-code-message").empty();
		var phoneNumber = $("input[name='dwfrm_profile_customer_phone']").val();
		var data = {'form-type': pageContext.type, 'phone' : phoneNumber};
		$.ajax({
            url: app.urls.getVerificationCode,
            data: data,
            type: 'POST',
            success: function(result) {
            	if(result.status == "ok") {
    				$(".get-code-message").text(app.resources.GetVerificationCodeOK);
    			} else {
    				if (data.status != "duplicated") {
    					$(".get-code-message").text(app.resources.GetVerificationCodeFailed);
    				}
    			}
            },
            error: function(jqXHR, textStatus, errorThrown) {
            	console.log(errorThrown);
            }
        });
    });
}
