/*
  This file regroups javascript code to handle minicart functions
  Some functions were previously in sandro.js
*/
Sandro.minicart.bindEvents = function(){
	Sandro.minicart.initRemoveButtons();
	Sandro.minicart.updateProductLineQuantity();
	Sandro.minicart.updateProductLineSize();
	Sandro.cart.initQtyButtons();
	Sandro.minicart.initMinicartCoupon();
}


/*
	This button removes an entire productLineItem from the cart
	from the minicart
*/
Sandro.minicart.initRemoveButtons = function(){
	$(".minicart-remove-button").click(function(e){
		e.preventDefault();
		$(this).closest('.mini-cart-product').fadeOut('slow');

		//Two DataLayer functions
		$(document).trigger("cart.removefromcart", [$(this).data()]);

		var url = $(this).attr("href");
		jQuery.ajax({
			url:url,
			cache:false
		}).done(function(data) {
			//Load data inside the mini cart
			app.minicart.show(data);
			//Restart-rebind minicart events
			Sandro.minicart.bindEvents();
		});
	});
}

/*
	Handle ajax calls to CartAjax-UpdateProductLineQuantity to modify
	productLineItem quantity (add or remove) in minilineitems.isml
*/
Sandro.minicart.updateProductLineQuantity = function() {
	$(".minicart-updatequantity").click(function(e){
		e.preventDefault();

		$(".mini-cart-loading").addClass("active");

		var dataUUID = $(this).parents(".mini-cart-product").data("uuid");

		/*
			Google Tag commented because client has not yet provided complete dataLayer instructions
			dataLayer.push({'event': 'updateProductQuantity', 'from' : 'Roll-Over Cart'});
		*/
		//Get the URL : (CartAjax-UpdateProductLineQuantity, pid, qty)
		var url = $(this).attr("href");
		jQuery.ajax({
			url:url,
			cache:false
		}).done(function(data) {

			//Load data inside the mini cart
			app.minicart.show(data);
			//Restart-rebind minicart events
			Sandro.minicart.bindEvents();

			$(".mini-cart-product").each(function() {
				var _this = $(this);
				var thisData = _this.data("uuid");

				if(thisData === dataUUID){
					_this.addClass("updateProductMC");
				}
			});

		});
	});
}

/*
	Handle ajax calls to CartAjax-UpdateProductLineSize to modify
	productLineItem size in minilineitems.isml
*/
Sandro.minicart.updateProductLineSize = function() {
	$('.minicartSizes').on('change', function(e){
		//Get pid, uuid to build CartAjax-UpdateProductLineItem url
		var mcpid = $(this).find('option:selected').data("minicartpid");
		var mcuuid = $(this).find('option.selected').data("minicartuuid");
		var qty = $(this).closest('.mini-cart-product').data("itemqty");

		//Build CartAjax-UpdateProductLineItem url
		var url = app.urls.minicartUpdateSize;
		url = app.util.appendParamToURL(url, 'pid', mcpid);
		url = app.util.appendParamToURL(url, 'uuid', mcuuid);
		url = app.util.appendParamToURL(url, 'Quantity', qty);

		jQuery.ajax({
			url:url,
			cache:false
		}).done(function(data) {
			//Load data inside the mini cart
			app.minicart.show(data);
			//Restart-rebind minicart events
			Sandro.minicart.bindEvents();

			//Hightlight the modified product in yello
			$(".mini-cart-product").each(function() {
				var _this = $(this);
				var thisData = _this.data("uuid");

				if(thisData === mcuuid){
					_this.addClass("updateProductMC");
				}
			});
		});
	});
}

Sandro.minicart.initMinicartCoupon = function() {
	window.app = window.app || {};

	var couponEl = $('.mini-cart-coupon'),
		input = couponEl.find('input'),
		errorEl = couponEl.find('.coupon-error'),
		crmCouponSelectBox = $("#mini-cart .crm_coupon"),
		crmCouponValue = $(".hidden-crm-coupon"),
		crmCouponSubmit = $("#button-apply-crm-coupon");

	couponEl.on('click', 'button', function(e){
		e.preventDefault();
		errorEl.empty().removeClass('coupon-inform');

		var val = input.val();

		if (val.length===0) {
			errorEl.text(app.resources.COUPON_CODE_MISSING);
			return;
		}

		var url = app.util.appendParamsToUrl(app.urls.addCoupon, {
				couponCode: val,
				format: 'ajax'
			});

		$.getJSON(url, function(data) {
			var fail = false,
				msg = '';

			if (!data) {
				msg = app.resources.BAD_RESPONSE;
				fail = true;
			} else {
				fail = !data.success;
				msg = data.message;
			}

			dataLayer.push({
				'event': 'addPromoCode',
				'promoCode': val,
				'validity': fail ? 'KO' : 'OK',
				'discount': null
			});

			if (fail) {
				errorEl.text(msg);
				return;
			}
			
			if (!fail && !data.applied) {
				errorEl.text(msg);
				return;
			}
			errorEl.empty();
			$.ajax({
				url: app.urls.minicartView,
				cache:false,
				success: function(response) {
					app.minicart.show(response);
					// display coupon inform message
					$('#mini-cart').find('.coupon-error').text(msg).addClass('coupon-inform');
					Sandro.minicart.bindEvents();
				}
			});
		});
	});
	crmCouponSelectBox.on('change', function() {
		var couponVal = $(this).val();
		crmCouponValue.val(couponVal);
	});
	crmCouponSubmit.on('click', function() {
		var couponVal = crmCouponValue.val();
		if (couponVal != '') {
			$.ajax({
				url: app.urls.addCRMCoupon,
				cache:false,
				data: { couponCode: couponVal }, 
				type : "POST",
				dataType : "json",
				success: function(response) {
					var msg = '';
					var fail = false;
					if (response.response_code === 'FAILED') {
						msg = app.resources.BAD_RESPONSE;
						fail = true;
					}
					
					if (fail) {
						errorEl.text(app.resources[response.response_message]);
						return;
					}
					errorEl.empty();
					
					$.ajax({
						url: app.urls.minicartView,
						cache:false,
						success: function(response) {
							app.minicart.show(response);
							// display coupon inform message
							$('#mini-cart').find('.coupon-error').text(msg).addClass('coupon-inform');
							Sandro.minicart.bindEvents();
						}
					});
				}
			});
		}
	});

}
