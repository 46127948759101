Sandro.header.bindEvents = function(){
	Sandro.header.btnScroll();
	Sandro.header.scrollTop();
	Sandro.header.menu();
	Sandro.header.search();
	Sandro.header.searchAsYouType();
	Sandro.header.miniwishlist();
	Sandro.header.logo();
}

// remove catalog cookie when clicking logo
Sandro.header.logo = function(){
	$(document).on('click.logo', function(){
		$.removeCookie('Sandro-HK_universeValue', { path: '/' }); // Sandro-HK hardcode
	});
};

Sandro.header.search = function(){
	var $cache = {};

	$cache = {
		btnOpenSearch : $(".btn-open-search"),
		btnCloseSearch : $(".close-search"),
		userNav : $(".userNav")
	};

	$cache.btnOpenSearch.on("click", function() {
		$cache.userNav.addClass("search-active");
		Sandro.header.overlay.create("black", ".userNav", "#header");
	});

	$cache.btnCloseSearch.on("click", function() {
		$cache.userNav.removeClass("search-active");
		Sandro.header.overlay.destroy();
	});

	$(document).on("click", ".overlay", function() {
		if($cache.userNav.hasClass("search-active")){
			$cache.userNav.removeClass("search-active");
			Sandro.header.overlay.destroy();
		}
	});
}

Sandro.header.menu = function(){

	var $cache = {};
	var heightNav = 60;

	$cache = {
		nav : $("#navigation"),
		menuCat : $(".menu-category"),
		userMenu: $('.menu-utility-user'),
		btnMobMenu : $(".mobile-menu"),
		btnCloseMenu : $(".btn-close-mob-menu"),
		btnBackMenu : $(".btn-back-to-main-menu"),
		topHeader: $("#header header")
	};

	/* Expand Sub Menu */
	$cache.menuCat.find("> li").on("mouseenter", function() {
		$(".menu-wrapper").removeAttr("style");
		var subMenuLength = $(this).find(".menu-wrapper").length;

		if(window.innerWidth > 1023 && subMenuLength){
			var subMenu = $(this).find(".menu-wrapper");
			var heightSubMenu = subMenu.outerHeight();
			var newHeight = heightSubMenu + heightNav;

			$(".overlay-nav").addClass("active");

			$cache.nav.height(newHeight);
			$cache.nav.css("z-index","99");
			$cache.topHeader.css("z-index","99");
			subMenu.css({
				'z-index': 9999,
				'display': 'block'
			});
		}

	}).on("mouseleave", function() {
		$(".overlay-nav").removeClass("active");
		var subMenuLength = $(this).find(".menu-wrapper").length;
		var subMenu = $(this).find(".menu-wrapper");
		if(window.innerWidth > 1023 && subMenuLength){
			$cache.nav.height(60);
			$cache.topHeader.removeAttr("style");
		}

	});

	$cache.nav.on("mouseleave", function() {
		if(window.innerWidth > 1023){
			Sandro.header.overlay.destroy();
		}
	});

	$cache.userMenu.on("click", '.user-account', function(e) {
		if ($('html').hasClass('touch')) {
			e.preventDefault();
		}
	});

	/* responsive menu function */
	var mainMenuResponsive = {
		init : function() {
			/* btn : open responsive menu */
			$cache.btnMobMenu.on("click", function() {
				mainMenuResponsive.open();
			});

			/* btn : close responsive menu */
			$cache.btnCloseMenu.on("click", function() {
				mainMenuResponsive.close();
			});

			/* btn : back to main responsive menu */
			$cache.btnBackMenu.on("click", function() {
				mainMenuResponsive.backMain();
			});

			/* close responsive menu on click on overlay */
			$(document).on("click", ".overlay", function() {
				if($cache.nav.hasClass("nav-responsive-open")){
					mainMenuResponsive.close();
				}
			});

			/* close responsive menu on window resize */
			$(window).on("resize", function() {
				$cache.nav.removeAttr("style");

				if($cache.nav.hasClass("nav-responsive-open") && window.innerWidth > 1023){
					mainMenuResponsive.close();
				}
			});

			/* sub menu responsive, including sub menu account responsive (.label-my-account) */
			$(".menu-category > li a.level-1, .label-my-account").on("click", function(event) {

				if(window.innerWidth < 1024){
					event.preventDefault();
				}

				var subMenuLength = $(this).parents("li").find(".menu-wrapper").length;

				if(subMenuLength){
					$(this).parents("li").addClass("sub-menu-open");
					$cache.nav.addClass("nav-sub-menu-open");
				} else {
					var redirect = $(this).attr("href");
					window.location.href = redirect;
				}
			});
		},
		open : function() {
			var scroll = parseInt($(window).scrollTop());
			console.log(scroll);
			$cache.btnMobMenu.addClass("active");
			$cache.nav.addClass("nav-responsive-open");
			Sandro.header.overlay.create("black", "#navigation", "#header");
			$("html").addClass("noScroll");
			$('#wrapper').css({marginTop: -(scroll)});
		},
		close : function() {
			var scroll = parseInt($('#wrapper').css("marginTop").replace('px', ''));
			console.log(scroll);
			$cache.btnMobMenu.removeClass("active");
			$cache.nav.removeClass("nav-responsive-open");
			Sandro.header.overlay.destroy();
			$("html").removeClass("noScroll");
			$('#wrapper').css({marginTop: 0});
			$(window).scrollTop(-(scroll));
		},
		backMain : function() {
			$cache.menuCat.find("> li").removeClass("sub-menu-open");
			$cache.nav.removeClass("nav-sub-menu-open");
		}
	};

	mainMenuResponsive.init();

	/* sticky */
	var sticky = false;
	var scrollActiveSticky = 60;
	if (!$('.header-light ').length) {
		var scrollWrapper = $('body');
		scrollWrapper.on("scroll", function() {
			if(sticky === false && scrollWrapper.scrollTop() > scrollActiveSticky){
				$("#header").addClass("sticky-header");
				sticky = true;
			} else if(sticky === true && scrollWrapper.scrollTop() < scrollActiveSticky){
				$("#header").removeClass("sticky-header");
				sticky = false;
			}

		});
	}

	var $menuGender = $('.universes-links-desktop .switch-gender');
	if ($menuGender.length) {
		$menuGender.on('click', 'a', function(e){
			if ($(this).closest('li').hasClass('active')) {
				e.preventDefault();
			}
		});
	}
}

Sandro.header.btnScroll = function(){
	$(document).on('click tap', '.scroll-top', function(e) {
		e.preventDefault();

		if($(window).scrollTop() > 0){
			$('body, html').animate({scrollTop : 0});
			return false;
		}
	});
}

Sandro.header.loginAndRedirect = function() {
	var connectUrl = $('#header .userNav .menu-utility-user a.user-login').attr('href');
	if (connectUrl) {
		var redirectUrl = window.location.href;

		// Hack : redirect to the full /homepage url if we're on the home, so that additional login params can be added.
		if (window.location.pathname == '/') {
			redirectUrl = app.urls.abshomepage;
		}

		redirectUrl = redirectUrl.indexOf('http') < 0 ? 'http://' + redirectUrl : redirectUrl;
		connectUrl = connectUrl.indexOf('?') > 0 ? connectUrl + '&' : connectUrl + '?';
		connectUrl = connectUrl + 'redirectto=' + redirectUrl;
		$('#header .userNav .menu-utility-user a.user-login').attr('href', connectUrl);
	}
}

Sandro.header.scrollTop = function() {
	var windowScrollTop = Math.round($(window).scrollTop());
	var btnVisible = 0;

	function btnScrollUp() {
	if($('.scroll-top').length > 0){
		var sTop = $('.grid-tile').height();
		sTop = sTop*0.7;
		if ((windowScrollTop >= sTop) && (btnVisible == 0)){
			btnVisible = 1;
			$('.scroll-top').animate({
				'opacity' : '1'
			}, {duration:500});
		} else if ((windowScrollTop < sTop) && (btnVisible == 1)){
			btnVisible = 0;
			$('.scroll-top').animate({
				'opacity' : '0'
			});
		}
	}
	}

	$(window).scroll(function() {
		windowScrollTop = Math.round($(window).scrollTop());

		btnScrollUp()
	});
}

Sandro.header.overlay = {

	create: function(colorOverlay, el, prependEl) {
		var overlayLength = $(".overlay");
		var color = (colorOverlay === "white") ? "white" : "black";
		overlayLength.addClass(color + "-overlay");
	},
	destroy: function(el, prependEl) {
		var overlayLength = $(".overlay");
		if (overlayLength){
			overlayLength.removeClass("black-overlay white-overlay");
		}
	}
}

Sandro.header.searchAsYouType = function(){
	//Make sure that suggestions have been enabled
	if ($('.enableSuggestions').length) {

		//When user types in first 3 letters, insert suggestions via ajax:
		$('#search-form .search-input').keyup(function(){
			var searchForm = $(this).closest('form');
			var urlSearchForm = searchForm.attr('data-action');
			var method = searchForm.attr('method');
			var value = $(this).val();

			if($(this).val().length > 2){
				$.ajax({
					type: method,
					url: urlSearchForm,
					cache: false,
					data: 'q='+value
				})
				.done(function( data ){
					$('#search-results').html(data);
				});
			}
			else {
				$('#search-results').html('');
			}
		});
	}
}


Sandro.header.miniwishlist = function(){
	window.app = window.app || {};
	var t;
	// mini-wishlist update event
	$('.mini-wishlist').on('updated.miniwishlist', function(){
		$this = $(this);
		$this.find('.icon-star').addClass('tada');

		t && clearTimeout(t);
		t = setTimeout(function(){
			$this.find('.icon-star').removeClass('tada')
		}, 2000);

		$.ajax({
			url: $this.data('wishlist'),
			type: 'GET',
			success: function(response){
				$this.find('.mini-wishlist-content').html(response);
			}
		});
	}).on('click.miniwishlist', '.WishlistRemove', function(e){
		e.preventDefault();
		var target = $(this).attr('href');
		$.ajax({
			url: target,
			type: 'GET',
			success: function(response){
				$('.mini-wishlist').trigger('updated.miniwishlist');
			}
		});
	}).on('click.miniwishlist', '.addToBasket', function(e){
		e.preventDefault();
		var $form = $(this).closest('form'),
			data = $form.serialize();
		app.cart.update(data, function (response) {
			app.minicart.show(response);
			Sandro.minicart.bindEvents();
		});
	});
};